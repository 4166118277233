import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Touring from "../../components/schedule/touring";
import { TouringDetails } from "../../components/touring-intro";
import PageContainer from "../../components/PageContainer";

const Benalla = () => (
    <Layout>
        <SEO title="KOFFIA Benalla" />

        <Touring city="Benalla" />
        <PageContainer>
            <TouringDetails />
        </PageContainer>
    </Layout>
);

export default Benalla;
